const variables = {
  colors: {
    base: '#121212',
    background: '#f7f7f7', //#141c23
    blackLight: '#141c23',
    gray: '#727d86',
    silver: '#969fa7',
    whitesmoke: '#f1f4f7',
    highlight: '#1da1f2',
    red: '#f7615f',
    orange: '#ffa22b',
    gradient: 'linear-gradient(-45deg,#ffa649,#f7645b,#805ed4)',
  },
  sizes: {
    bioWidth: '290px',
    maxWidth: '1100px',
  },
  sideSpace: {
    small: '20px',
    large: '1.5em',
    contentSmall: '20px',
    contentLarge: '2.5em',
  },
  responsive: {
    small: '500px',
    medium: '768px',
    large: '950px',
  },
};

export default variables;
